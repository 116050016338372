import axios from 'axios';
import { CALIFICACION_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;
axios.interceptors.request.use(reqConf, reqErr)
axios.interceptors.response.use(resFilter, resErr)

export const createScore = async (newScore) => {
  return axios.post(`${CALIFICACION_PATH}/guardar`, newScore);
};

// export const scoreList = async (fechaInicio, fechaFin) => {
//   return axios.get(`${CALIFICACION_PATH}/listado/recibidas?transportista=false&idEmpresa=1b645cfa-9e64-43cf-b8f8-8067b865059f&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
// };

// devuelve 3
// export const scoreList = async (fechaInicio, fechaFin) => {
//   return axios.get(`${CALIFICACION_PATH}/listado/recibidas?transportista=false&idEmpresa=1b645cfa-9e64-43cf-b8f8-8067b865059f`);
// };
// devuelve 10
// export const scoreList = async (fechaInicio, fechaFin) => {
//   return axios.get(`${CALIFICACION_PATH}/listado/recibidas?transportista=false`);
// };

// devuelve 7
export const scoreList = async (fechaInicio, fechaFin) => {
  return axios.get(`${CALIFICACION_PATH}/listado/recibidas?transportista=true`);
};
