import axios from 'axios';
import { SUSCRIPCION_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const choosePlan = async (suscripcion) => {
  return axios.post(`${SUSCRIPCION_PATH}/guardar`, suscripcion);
};

export const createSuscStripe = async (priceId, suscripcion) => {
  return axios.post(`${SUSCRIPCION_PATH}/stripe/guardar?priceId=${priceId}`, suscripcion);
};

export const getActiveSubscriptionsPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${SUSCRIPCION_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllActiveSubscriptionsWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${SUSCRIPCION_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deleteActiveSubscription = async (id) => {
  return axios.delete(`${SUSCRIPCION_PATH}/eliminar/${id}`);
};

export const createSuscription = async (suscription) => {
  return axios.post(`${SUSCRIPCION_PATH}/guardar`, suscription);
};
