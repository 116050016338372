export const timeFrame = [
    {
      label: '7D',
      value: '7 day',
      disabled: true
    },
    {
      label: '1M',
      value: '1 month',
      disabled: true
    },
    {
      label: '1A',
      value: '1 year',
      disabled: true
    },
    {
      label: 'MAX',
      value: 'maximum',
      disabled: true
    },
  ];