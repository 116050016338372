import React, { useContext } from 'react';
import { Box, Card, Divider, CardHeader, CardContent, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';

const LoadChart = ({ data, title, }) => {
  const [t] = useTranslation();
  const {padMarByScreen, heightCardHeader, heightScreen, sizeFontCardHeader, mMainCard}= useContext(ScreenSizeContext);


  return (
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb: 1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen,height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={title}
      />
      <Divider sx={{ backgroundColor: 'orange', marginBottom: heightScreen>900? 3:0, mx:2}}/>

      <CardContent>
        {data  && (
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              width="100%"
              height={500}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 190, 
                
              }}
              data={data }
            >
              <CartesianGrid strokeDasharray="3 3" horizontal vertical={false}/>
              <YAxis
                tickLine={false}
                domain={[0, 1]}
                tickFormatter={(str) => {
                  if (str >= 1000000000000)
                    return Number.isInteger(str / 1000000000000)
                      ? `${(str / 1000000000000).toString()}B`
                      : `${(str / 1000000000000).toFixed(1)}B`;

                  if (str >= 1000000)
                    return Number.isInteger(str / 1000000)
                      ? `${(str / 1000000).toString()}M`
                      : `${(str / 1000000).toFixed(1)}M`;

                  if (str >= 1000)
                    return Number.isInteger(str / 1000)
                      ? `${(str / 1000).toString()}K`
                      : `${(str / 1000).toFixed(1)}K`;

                  return Number.isInteger(str) ? str.toString() : str.toFixed(1);
                }}
                label={{ value: t("Number of loads"), angle: -90, position: 'insideLeft'
                
              }}
                dataKey="cantidad"
              />
              <XAxis
                dataKey="estado"
                tickSize={5}
                label={{ value: t("Loading status"), offset: 10, position:"insideTop",
                style: {  padding: "5px", borderRadius: "5px" }
            }}
                angle={-60}
                tick={{ fontSize: '1rem', fontWeight: 'bold' }}
                tickFormatter={(value) => t(value.replace(/([A-Z])/g, ' $1').toUpperCase()) }
                interval={0} 
                height={40} 
                style={{ textAnchor: 'end' }} 
                tickMargin={30} 
                
              />

              <Tooltip
                content={(props) => <CustomTooltip {...props} />}
              />
              <Bar dataKey="cantidad"   fill="#FFA500"
                activeBar={<Rectangle fill="#FFA500" stroke="#000" />}
                />
            </BarChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default LoadChart;

const CustomTooltip = ({ active, payload, label }) => {

  const [t] = useTranslation();
  if (active && payload && payload.length) {
    return (
      <Card>
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={`${t(label)}`}
        />
        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ paddingLeft: 1, paddingTop: 0, margin: 0, marginBottom: -2 }}>
          {payload?.map((el) => (
            <Box
              sx={{
                backgroundColor: 'white',
                marginTop: 1,
                borderLeft: 6,
                borderLeftColor: el.color,
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                paddingLeft: 0.5,
              }}
            >
              <Typography> {`Cantidad: ${el.payload.cantidad}`}</Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }
  return null;
};
