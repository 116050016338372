import React, { useState } from 'react';
import styled from '@emotion/styled';
import { alpha, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled(({ children, ...other }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...other}
  >
    {children}
  </Menu>
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiList-root': {
      padding: '4px 0',
    },
    '& .MuiListItem-root': {
      ...theme.typography.body1,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ActionsListTable = ({
  // ver detalles”, “pausar publicación”, “cancelar publicación
  data,
  handleViewRol,
  handleAssing,
  handleActive,
  handleActivationProcess,
  handleDeactivationProcess,
  handleReassign,
  handleOpen,
  handleUnfollow,
  handleReactivate,
  handlePausar,
  handleCancelar,
  handleDelete,
  handleStopWatching,
  handlePlaning,
  handleExecuting,
  handleFinish,
  handleDeleteFolder,
  handleChangeState,
  handleChangeStatus,
  handleAssociatedWO,
  handleAssociatedOM,
  handleSeeDocuments,
  handleSeeAssignments,
  handleCreateMilestone,
  handleCancel,
  handleCancelOS,
  handleViewProfile,
  handleEdit,
  handleDetalle,
  handleAssignResources,
  handleViewReasign,
  handleSeguimiento,
  handleContact,
  handleAssignACheck,
  handleAssignTo,
  handleAccept,
  handleRefuse,
  handleBlock,
  handleDuplicate,
  handleUnassign,
  handleViewMarketplace,
  handleScore,
  handleCreateOrder,
  handleViewOrder,
  handleViewMap,
  conditionals,
  resendInvitation,
  textToCopy,
  handleViewForm,
  handleViewFormResponse,
  handleReorder,
  handleViewTrip,
  handleCancelAssignment,
  handleCompleteAssignments,
  disabled,
  handleViewExpenses,
  handleRechazar,
  handleContactOs,
  handleAssignAppointment,
  handleDeleteProduct,
  handleEditProduct,
  handleViewAnswers,
  handleActivate,
  handleshowSteps,
  handleChangeAssignment,
  handleCompleteStep,
  handlecancelStep,
  handleComment,
  activo,
  step,
  styleIconButton,
  handleAddBranch,
  handleEditBranch,
  handleDeleteTree,
  handleAddProcedure,
  handleReadQuery,
  handleActiveQuery,
}) => {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <Tooltip disableInteractive> */}
      <IconButton onClick={handleOpenMenu} sx={styleIconButton}>
        <MoreVertIcon />
      </IconButton>
      {/* </Tooltip> */}

      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {handleOpen && (
          <MenuItem
            id="detalle"
            onClick={() => {
              handleOpen();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Open')}
          </MenuItem>
        )}
        {handleViewRol && (
          <MenuItem
            id="view"
            onClick={() => {
              handleViewRol();
              handleCloseMenu();
            }}
          >
            {t('Button.SeeRol')}
          </MenuItem>
        )}
        {handleAssing && (
          <MenuItem
            id="asignar"
            onClick={() => {
              handleAssing();
              handleCloseMenu();
            }}
          >
            {t('Button.Assign')}
          </MenuItem>
        )}

        {handleDetalle && (
          <MenuItem
            id="detalle"
            onClick={() => {
              handleDetalle();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Details')}
          </MenuItem>
        )}

        {handleAssignResources && (
          <MenuItem
            id="asignar recursos"
            onClick={() => {
              handleAssignResources();
              handleCloseMenu();
            }}
          >
            {t('Assign resources')}
          </MenuItem>
        )}

        {handleViewReasign && (
          <MenuItem
            id="detailll"
            onClick={() => {
              handleViewReasign();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.ViewOS')}
          </MenuItem>
        )}

        {handleSeguimiento && (
          <MenuItem
            id="seguimiento"
            onClick={() => {
              handleSeguimiento();
              handleCloseMenu();
            }}
          >
            {t('Button.Tracing')}
          </MenuItem>
        )}

        {handleActivationProcess && (
          <MenuItem
            id="changeActivationProcess"
            onClick={() => {
              handleActivationProcess();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.ActivateProcess')}
          </MenuItem>
        )}

        {handleDeactivationProcess && (
          <MenuItem
            id="changeActivationProcess"
            onClick={() => {
              handleDeactivationProcess();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.DesactivateProcess')}
          </MenuItem>
        )}

        {handleViewExpenses && (
          <MenuItem
            id="detalle"
            onClick={() => {
              handleViewExpenses();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-expenses')}
          </MenuItem>
        )}

        {handleCreateMilestone && (
          <MenuItem
            id="createMilestone"
            onClick={() => {
              handleCreateMilestone();
              handleCloseMenu();
            }}
          >
            Crear hito
          </MenuItem>
        )}

        {handleCreateOrder && (
          <MenuItem
            id="createOrder"
            onClick={() => {
              handleCreateOrder();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Create-order')}
          </MenuItem>
        )}

        {handleReactivate && (
          <MenuItem
            id="reactivate"
            onClick={() => {
              handleReactivate();
              handleCloseMenu();
            }}
          >
            Reactivar
          </MenuItem>
        )}
        {handleViewForm && (
          <MenuItem
            id="form"
            onClick={() => {
              handleViewForm();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-form')}
          </MenuItem>
        )}
        {handleViewFormResponse && step?.estado === 'Completado' && step?.idFormularioRespondido && (
          <MenuItem
            id="form"
            onClick={() => {
              handleViewFormResponse();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-form')}
          </MenuItem>
        )}

        {handleViewAnswers && (
          <MenuItem
            id="ver respuestas"
            onClick={() => {
              handleViewAnswers();
              handleCloseMenu();
            }}
          >
            {t('Button.ViewAnswers')}
          </MenuItem>
        )}

        {handleReorder && (
          <MenuItem
            id="reorder"
            onClick={() => {
              handleReorder();
              handleCloseMenu();
            }}
          >
            Reordenar
          </MenuItem>
        )}

        {handleViewProfile && (
          <MenuItem
            id="verPerfil"
            onClick={() => {
              handleViewProfile();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-profile')}
          </MenuItem>
        )}

        {handleEdit && (
          <MenuItem
            id="edit"
            onClick={() => {
              handleEdit();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Edit')}
          </MenuItem>
        )}

        {handleCancel && (
          <MenuItem
            id="cancel"
            onClick={() => {
              handleCancel();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Cancel')}
          </MenuItem>
        )}

        {handleCancelOS && (
          <MenuItem
            id="cancel"
            onClick={() => {
              handleCancelOS();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.CancelOS')}
          </MenuItem>
        )}

        {handleActive && (
          <MenuItem
            id="active"
            onClick={() => {
              handleActive();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Active')}
          </MenuItem>
        )}

        {conditionals === 'PENDIENTE' && data?.rol?.nombre !== 'Asociado' && (
          <MenuItem
            id="resendInvitation"
            onClick={() => {
              resendInvitation(data);
              handleCloseMenu();
            }}
          >
            {t('Configuration.ReInviteButton')}
          </MenuItem>
        )}

        {conditionals === 'PENDIENTE' && data?.rol?.nombre !== 'Asociado' && (
          <CopyToClipboard text={textToCopy}>
            <MenuItem
              id="copyUrl"
              onClick={() => {
                handleCloseMenu();
              }}
            >
              {t('Configuration.InviteUrl')}
            </MenuItem>
          </CopyToClipboard>
        )}
        {handleAssignACheck && (
          <MenuItem
            id="assignTo"
            onClick={() => {
              handleAssignACheck();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Assign-a-check')}
          </MenuItem>
        )}
        {handleAssignTo && activo && (
          <MenuItem
            id="assignTo"
            onClick={() => {
              handleAssignTo();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Assign')}
          </MenuItem>
        )}
        {handleAssociatedOM && (
          <MenuItem
            id="associatedOM"
            onClick={() => {
              handleAssociatedOM();
              handleCloseMenu();
            }}
          >
            OM Asociadas
          </MenuItem>
        )}

        {handleAssociatedWO && (
          <MenuItem
            id="associatedWO"
            onClick={() => {
              handleAssociatedWO();
              handleCloseMenu();
            }}
          >
            OT Asociadas
          </MenuItem>
        )}

        {handleSeeDocuments && (
          <MenuItem
            id="documents"
            onClick={() => {
              handleSeeDocuments();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-documentation')}
          </MenuItem>
        )}

        {handleSeeAssignments && (
          <MenuItem
            id="asiganciones"
            onClick={() => {
              handleSeeAssignments();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-assignments')}
          </MenuItem>
        )}

        {handlePlaning && (
          <MenuItem
            id="changeState"
            onClick={() => {
              handlePlaning();
              handleCloseMenu();
            }}
          >
            {t('OM.planing')}
          </MenuItem>
        )}

        {handleChangeState && (
          <MenuItem
            id="changeState"
            onClick={() => {
              handleChangeState();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.cambiarEstado')}
          </MenuItem>
        )}
        {handleChangeStatus && (
          <MenuItem
            id="changeStatus"
            onClick={() => {
              handleChangeStatus();
              handleCloseMenu();
            }}
          >
            Cambiar estado
          </MenuItem>
        )}

        {handleFinish && (
          <MenuItem
            id="changeState"
            onClick={() => {
              handleFinish();
              handleCloseMenu();
            }}
          >
            {t('OM.finish')}
          </MenuItem>
        )}

        {handleExecuting && (
          <MenuItem
            id="changeState"
            onClick={() => {
              handleExecuting();
              handleCloseMenu();
            }}
          >
            {t('OM.execute')}
          </MenuItem>
        )}

        {handleUnassign && (
          <MenuItem
            id="desasignar"
            onClick={() => {
              handleUnassign();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Unassign-Load')}
          </MenuItem>
        )}

        {handlePausar && (
          <MenuItem
            id="pausar"
            onClick={() => {
              handlePausar();
              handleCloseMenu();
            }}
          >
            Pausar
          </MenuItem>
        )}

        {handleContact && (
          <MenuItem
            id="contactar"
            onClick={() => {
              handleContact();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Contact')}
          </MenuItem>
        )}
        {handleContactOs && (
          <MenuItem
            id="contactar"
            onClick={() => {
              handleContactOs();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Contact')}
          </MenuItem>
        )}
        {handleAccept && (
          <MenuItem
            id="aceptar"
            onClick={() => {
              handleAccept();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Accept')}
          </MenuItem>
        )}

        {handleRefuse && (
          <MenuItem
            id="rechazar"
            onClick={() => {
              handleRefuse();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Refuse')}
          </MenuItem>
        )}
        {handleBlock && (
          <MenuItem
            id="bloquear"
            onClick={() => {
              handleBlock();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Block')}
          </MenuItem>
        )}
        {handleDuplicate && (
          <MenuItem
            id="duplicar"
            onClick={() => {
              handleDuplicate();
              handleCloseMenu();
            }}
          >
            Duplicar
          </MenuItem>
        )}

        {handleViewOrder && (
          <MenuItem
            id="viewOrder"
            onClick={() => {
              handleViewOrder();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-order')}
          </MenuItem>
        )}
        {handleReassign && (
          <MenuItem
            id="reassign"
            onClick={() => {
              handleReassign();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Reassign')}
          </MenuItem>
        )}

        {handleViewMarketplace && (
          <MenuItem
            id="verEnMarketplace"
            onClick={() => {
              handleViewMarketplace();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-on-marketplace')}
          </MenuItem>
        )}

        {handleViewMap && (
          <MenuItem
            id="viewMap"
            onClick={() => {
              handleViewMap();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-map')}
          </MenuItem>
        )}

        {handleDelete && (
          <MenuItem
            id="eliminar"
            onClick={() => {
              handleDelete();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Delete')}
          </MenuItem>
        )}

        {handleStopWatching && (
          <MenuItem
            id="Dejar de ver"
            onClick={() => {
              handleStopWatching();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Stop-watching')}
          </MenuItem>
        )}

        {handleDeleteFolder && (
          <MenuItem
            id="deleteFolder"
            onClick={() => {
              handleDeleteFolder();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.DeleteFolder')}
          </MenuItem>
        )}
        {handleActivate && (
          <MenuItem
            id="activo"
            onClick={() => {
              handleActivate();
              handleCloseMenu();
            }}
          >
            {activo ? t('ActionListTable.Deactivate') : t('ActionListTable.Active')}
          </MenuItem>
        )}

        {handleCancelar && (
          <MenuItem
            id="cancelar"
            onClick={() => {
              handleCancelar();
              handleCloseMenu();
            }}
          >
            Cancelar
          </MenuItem>
        )}

        {handleUnfollow && (
          <MenuItem
            id="unfollow"
            onClick={() => {
              handleUnfollow();
              handleCloseMenu();
            }}
          >
            Dejar de seguir
          </MenuItem>
        )}

        {handleScore && (
          <MenuItem
            id="score"
            onClick={() => {
              handleScore();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Score')}
          </MenuItem>
        )}

        {handleViewTrip && (
          <MenuItem
            id="view-trip"
            onClick={() => {
              handleViewTrip();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.View-travel')}
          </MenuItem>
        )}

        {handleCancelAssignment && (
          <MenuItem
            id="cancel-assignment"
            onClick={() => {
              handleCancelAssignment();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Cancel-assignment')}
          </MenuItem>
        )}

        {handleCompleteAssignments && (
          <MenuItem
            id="complete-assignments"
            onClick={() => {
              handleCompleteAssignments();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Complete-assignments')}
          </MenuItem>
        )}

        {handleRechazar && (
          <MenuItem
            id="rechazarOs"
            onClick={() => {
              handleRechazar();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Refuse')}
          </MenuItem>
        )}
        {/* {handleAssignAppointment && (
          <MenuItem
            id="Appointment"
            onClick={() => {
              handleAssignAppointment();
              handleCloseMenu();
            }}
          >
            Turno
          </MenuItem>
        )} */}
        {handleDeleteProduct && (
          <MenuItem
            id="DeleteProduct"
            onClick={() => {
              handleDeleteProduct();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Delete')}
          </MenuItem>
        )}
        {handleEditProduct && (
          <MenuItem
            id="edit Product"
            onClick={() => {
              handleEditProduct();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Edit')}
          </MenuItem>
        )}

        {handleshowSteps && (
          <MenuItem
            id="show Steps"
            onClick={() => {
              handleshowSteps();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.See steps')}
          </MenuItem>
        )}
        {handleComment && (
          <MenuItem
            id="Comew"
            onClick={() => {
              handleComment();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Comment')}
          </MenuItem>
        )}

        {handleChangeAssignment && step?.estado !== 'Completado' && (
          <MenuItem
            id="Change Assignment"
            onClick={() => {
              handleChangeAssignment();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Change assignment')}
          </MenuItem>
        )}
        {handleCompleteStep && step?.estado !== 'Completado' && (
          <MenuItem
            id="Change Assignment"
            onClick={() => {
              handleCompleteStep();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Complete Step')}
          </MenuItem>
        )}

        {handlecancelStep && step?.estado === 'Completado' && (
          <MenuItem
            id="Change Assignment"
            onClick={() => {
              handlecancelStep();
              handleCloseMenu();
            }}
          >
            {t('Cancelar Paso')}
          </MenuItem>
        )}

        {handleAddBranch && (
          <MenuItem
            id="Add Branch"
            onClick={() => {
              handleAddBranch();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Add Branch')}
          </MenuItem>
        )}

        {handleEditBranch && (
          <MenuItem id="Edit Branch"
          onClick={() => {
            handleEditBranch();
            handleCloseMenu();
          }}>
            {t('ActionListTable.Edit Tree')}
          </MenuItem>
        )}

        {handleDeleteTree && (
          <MenuItem
            id="Delete Tree"
            onClick={() => {
              handleDeleteTree();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Delete Tree')}
          </MenuItem>
        )}

        {handleReadQuery && (
          <MenuItem
            id="readQuery"
            onClick={() => {
              handleReadQuery();
              handleCloseMenu();
            }}
          >
            {t('Backoffice.queriesTable.Modal.consultaLeida')}
          </MenuItem>
        )}

        {handleActiveQuery && (
          <MenuItem
            id="activeQuery"
            onClick={() => {
              handleActiveQuery();
              handleCloseMenu();
            }}
          >
            {t('Backoffice.queriesTable.Modal.buttonTextActivarConsulta')}
          </MenuItem>
        )}

        {handleAddProcedure && (
          <MenuItem
            id="Add Procedure"
            onClick={() => {
              handleAddProcedure();
              handleCloseMenu();
            }}
          >
            {t('ActionListTable.Add Procedure')}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};
ActionsListTable.propTypes = {
  handleViewRol: PropTypes.func,
  handleDelete: PropTypes.func,
  handleChangeState: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleExecuting: PropTypes.func,
  handleFinish: PropTypes.func,
  handlePlaning: PropTypes.func,
  handleAssociatedWO: PropTypes.func,
  handleAssociatedOM: PropTypes.func,
  handleSeeDocuments: PropTypes.func,
  handleCreateMilestone: PropTypes.func,
  handleEdit: PropTypes.func,
  handleContact: PropTypes.func,
  handleUnfollow: PropTypes.func,
  handleCancel: PropTypes.func,
  handleActive: PropTypes.func,
  handleCancelOS: PropTypes.func,
  handleActivationProcess: PropTypes.func,
  handleDeactivationProcess: PropTypes.func,
  handleReadQuery: PropTypes.func,
  handleActiveQuery: PropTypes.func,
};

export default ActionsListTable;
