import React, { createContext, useEffect, useState } from 'react';

import { getOtDetails } from '../../../../api/viaje';

const OtContext = createContext();
export const OtProvider = ({ children }) => {
  const [wayPointsToEdit, setWayPointsToEdit] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalPoints, setOriginalPoints] = useState(null);
  const [wayPoints, setWayPoints] = useState(null);
  const [viaje, setViaje] = useState(null);

  async function loadTravel(id) {
    try {
      const { data: travel } = await getOtDetails(id);
      setViaje(travel);
      setOriginalPoints(travel?.wayPointsViaje);
      if (travel.wayPointsViaje.length > 0) {
        setStartDate(new Date(travel.wayPointsViaje[0].llegadaEstimada));
      } else {
        setStartDate(new Date(travel.partida))
      }
    } catch (e) {
      console.log(e);
    }
  }

  // logica waypoint
  const getwayPoints = () => {
    const puntos = wayPointsToEdit?.map((waypoint, index) => {
      return {
        direccion: waypoint.address,
        id: waypoint?.id,
        idUbicacion: waypoint?.idUbicacion,
        idCarga: viaje.id,
        latitud: waypoint.lat,
        longitud: waypoint.lng,
        orden: index,
        tiempoTransito: index === 0 ? null : routeInfo?.[index - 1]?.durationText,
        llegadaEstimada: index === 0 ? startDate : routeInfo?.[index - 1]?.tiempoEstimado,
        llegadaEstimadaStr: index === 0 ? startDate : routeInfo?.[index - 1]?.tiempoEstimado,
        tiempoServicio: waypoint?.timePuntoControl || null,
        tiempos:
          index === 0
            ? null
            : (routeInfo?.[index - 1]?.tiempoAdicional.length > 0 &&
                routeInfo?.[index - 1]?.tiempoAdicional?.map((tiempo) => ({
                  descripcion: tiempo.descripcion,
                  tiempoServicio: tiempo.tiempoServicio,
                  idWaypoint: waypoint?.id || null,
                  id: tiempo?.id || null,
                }))) ||
              null,
        wp:
          waypoint?.type === 'PUNTO DE CONTROL'
            ? null
            : (() => {
                const matchingWp = originalPoints.find((wp) => {
                  return wp.id === waypoint.id;
                });
                return matchingWp ? matchingWp.wp : null;
              })(),
      };
    });
    setWayPoints(puntos);
    setEndDate(routeInfo?.[routeInfo.length - 1]?.tiempoEstimado || null);
  };

  useEffect(() => {
    getwayPoints();
  }, [wayPointsToEdit, routeInfo, startDate]);

  const data = {
    wayPointsToEdit,
    setWayPointsToEdit,
    routeInfo,
    setRouteInfo,
    startDate,
    setStartDate,
    setOriginalPoints,
    wayPoints,
    endDate,
    loadTravel,
    viaje,
  };

  return <OtContext.Provider value={data}>{children}</OtContext.Provider>;
};

export default OtContext;
