import React, { useContext, useEffect, useState } from 'react';
import {Box, Grid, BottomNavigation, BottomNavigationAction, Card, Divider, CardHeader, CardContent, Typography,useTheme} from '@mui/material';
import { MapContainer, TileLayer} from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import 'leaflet/dist/leaflet.css';
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import { modules } from '../../constants/permissions';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';

const HeatMap = ({data, module})=>{
  const [t] = useTranslation();
  const {padMarByScreen, heightCardHeader, sizeFontCardHeader, heightScreen, mMainCard}= useContext(ScreenSizeContext)
  const [hmData, setHmData] = useState([]);
  const center=[-24.103865, -59.551245]
  useEffect(()=>{
    if(data?.length>0){
      const arrayCoords = data.map((item) => [
        module === modules.load ? item.latInicial : item.latitudInicial,
        module === modules.load ? item.longInicial : item.longitudInicial,
        3,
      ]);
      setHmData(arrayCoords);}
  },[data])

  return(
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb: 1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen,height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={t('Heat-map')}
      />
      <Divider sx={{ backgroundColor: 'orange', marginBottom: heightScreen>900? 1:0, mx:2 }} />
      <CardContent sx={{display:'flex', justifyContent:'center'}}>
          {hmData.length>0 && <MapContainer center={center} zoom={15} style={{width:'100vw', height:'425px'}}>
              <HeatmapLayer 
              fitBoundsOnLoad
              fitBoundsOnUpdate
              points={hmData}
              longitudeExtractor={m => m[1]}
              latitudeExtractor={m => m[0]}
              intensityExtractor={m => parseFloat(m[2])}
              radius={15}
              max={5.0}
              blur={15}
              gradient= {{
                  0.1: 'blue',
                  0.5: 'cyan',
                  0.7: 'yellow',
                  0.9: 'red'}
                }
              opacity={1}
              minOpacity={0.7}
              />
              <TileLayer
                  url='https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=NOsNaLADreQ6W2xr9rcv'
                  attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'/>
  
          </MapContainer>}
      </CardContent>
    </Card>
  )
}

export default HeatMap;