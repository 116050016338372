import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import { sxTableButtons } from '../../constants/styleTableButton';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import { getListLoad } from '../../api/cargo';
import ControlPanel from '../../components/controlPanels/withTimeframe/ControlPanel';
import { getCompany } from '../../api/company';
import useAuth from '../../hooks/useAuth';
import { modules } from '../../constants/permissions';
import { getOrderList } from '../../api/order';
import LoadsPerTime from '../cargo/panelControl/LoadsPerTime';

const KPIPanel = () => {
  const auth = useAuth();
  const [t] = useTranslation();
  const theme = useTheme();
  const {
    mxTableButtons,
    myTableButtons,
    widthScreen,
    mtTypographyTableButtons,
    typographyVariantButton,
  } = useContext(ScreenSizeContext);
  const [firstSession, setFirstSession] = useState(null);
  const [loads, setLoads] = useState(null);
  const [orders, setOrders] = useState(null);
  const [enableView, setEnableView] = useState(false);
  const [error, setError] = useState(false);

  const BCrumb = [
    {
      to: '/torre-de-control/inicio',
      title: 'Panel de Control',
    },
    {
      title: t('Control-panel'),
    },
  ];

  const fetchData = async () => {
    try {
      const [respFirstSession, respLoad, respOrders] = await Promise.all([
        getCompany(auth?.authenticatedData?.idEmpresa),
        getListLoad(),
        getOrderList(),
      ]);
      setFirstSession(respFirstSession.data.primeraSesion);
      setLoads(respLoad.data);
      setOrders(respOrders.data);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setEnableView(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log(orders?.length > 0);

  return (
    <PageContainer title="KPI Torre Control">
      <Breadcrumb items={BCrumb} />
      <Button
        sx={{ ...sxTableButtons, mx: mxTableButtons, my: myTableButtons }}
        variant="contained"
        color="primary"
        size="small"
        endIcon={
          <RefreshIcon
            style={{ fontSize: '24px' }}
            sx={{ mr: widthScreen > 450 ? 0.5 : 0.4, ml: widthScreen > 420 ? 0 : -0.6 }}
          />
        }
        // onClick={syncUpKpi}
      >
        <Typography
          variant={typographyVariantButton}
          sx={{ ml: widthScreen > 420 ? 0 : -0.5, mt: mtTypographyTableButtons }}
        >
          {t('Update Charts')}
        </Typography>
      </Button>
      {error ? (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '50vh',
          }}
        >
          <WifiOffIcon
            fontSize="large"
            sx={{ width: '10rem', height: '10rem', color: theme.palette.primary.main }}
          />
          <Typography variant="h3">{t('server error')}</Typography>
          <Typography variant="h4" textAlign="center" py={0.5}>
            {t('mjs-server error')}
          </Typography>
          <Typography variant="h4" textAlign="center" py={0.5}>
            {t('mjs2-server error')}
          </Typography>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <Divider sx={{ height: 10, backgroundColor: 'orange', my: 4, mx: 2 }} />
          </Grid>
          {loads?.length > 0 && (
            <Grid item md={6} sm={12} xs={12}>
              <ControlPanel
                title={t('Loads-and-amounts-by-periods')}
                data={loads}
                firstSession={firstSession}
                module={modules?.load}
              />
            </Grid>
          )}
          {orders.length > 0 && (
            <Grid item md={6} sm={12} xs={12}>
              <LoadsPerTime
                title="Pedidos entregados"
                data={orders}
                firstSession={firstSession}
                module={modules.load}
              />
            </Grid>
          )}
        </Grid>
      )}
    </PageContainer>
  );
};

export default KPIPanel;
