import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material';
import {
  Line,
  Bar,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../constants/colors';
import { sevenDaysData, lastMonthData, lastYearData, maxData } from '../utils/handleDataToTimeFrame';
import { modules } from '../../../constants/permissions';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';
import { timeFrame } from '../utils/timesFrame';
import TimeFrame from '../components/TimeFrame';
import { dateFormatter, numberSimplifier } from '../utils/handleTickFormatter';

const ControlPanel = ({ title, data, firstSession, module }) => {
  const [t] = useTranslation();
  const {padMarByScreen, heightCardHeader, typographyKPI, widthScreen, sizeFontCardHeader, mMainCard, heightKPI}= useContext(ScreenSizeContext)
  const [colores, setColores] = useState(colors);
  const [tF7D, setTF7D] = useState([]);
  const [tF1M, setTF1M] = useState([]);
  const [tF1A, setTF1A] = useState([]);
  const [tFMax, setTFMax] = useState([]);
  const [dataToGraph, setDataToGraph] = useState([]);
  const copyTimeFrame = timeFrame.map(frame => ({ ...frame }))
  const [timeFrameState, setTimeFrameState] = useState();
  const [selectedTimeFrame, setSelecTimeFrame] = useState();

  const getColor = (count) => {
    const randomColors = [];
    const caracteresHex = '0123456789ABCDEF';

    for (let i = 0; i < count; i++) {
      let color = '#';
      for (let j = 0; j < 6; j++) {
        color += caracteresHex[Math.floor(Math.random() * 16)];
      }
      randomColors.push(color);
    }

    setColores([...colores, randomColors]);
  };

  const handleOnChangeTimeFrame = (newValue) =>{
    setSelecTimeFrame(copyTimeFrame[newValue]);
    setDataToGraph(
        newValue === 0 ? tF7D : newValue === 1 ? tF1M : newValue === 2 ? tF1A : tFMax,
    );
  }

  useEffect(() => {
    if (data && data.length > 0) {
      getColor(Object.keys(data[0]).length - 1);

      const week= sevenDaysData(data);
      if (week?.length>0){
        setTF7D(week);
        copyTimeFrame[0].disabled=false
      }

      const month = lastMonthData(data);
      if (month?.length>0){
        setTF1M(month);
        copyTimeFrame[1].disabled=false
      }  

      const year = lastYearData(data);
      if (year?.length>0){
        setTF1A(year);
        copyTimeFrame[2].disabled=false
      }

      const max = maxData(data, firstSession);
      if (max?.length>0){
        setTFMax(max);
        copyTimeFrame[3].disabled=false
      }

      setSelecTimeFrame(month? copyTimeFrame[1]: copyTimeFrame[3])
      setDataToGraph(month || max);
      setTimeFrameState(copyTimeFrame);
    }
  }, [data]);

  return (
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb: 1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen, height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={title}
      />
      <Divider sx={{ backgroundColor: 'orange', marginBottom: 0, mx:2 }}/>
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12}>
            <Box justifyContent="center" mb={3}>
              <TimeFrame
                timeFrame={timeFrameState}
                selectedTimeFrame={selectedTimeFrame}
                handleOnChangeTimeFrame={handleOnChangeTimeFrame}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item  xs={12} sm={12} md={12}>
            {dataToGraph.length > 0 && (
              <ResponsiveContainer width="100%" height={heightKPI}>
                <ComposedChart data={dataToGraph} baseValue="dataMin" 
                margin={widthScreen>450?{ left:10, bottom: 0, top:30 }: { left: 0, bottom: 0, top:0 }}>
                  <defs>
                    <linearGradient id="colorArea" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#47F13F" stopOpacity={0.5} />
                      <stop offset="70%" stopColor="white" stopOpacity={0.05} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickSize={10}
                    tickFormatter={(str) => dateFormatter(str, selectedTimeFrame, t)}
                     
                    /* label={{ value: t('Periods'), position: 'insideBottomRight', offset:-8}} */
                  />
                  <YAxis
                    yAxisId="promedioId"
                    tickCount={100}
                    axisLine={false}
                    tickLine={false}
                    tickSize={1}
                    tick={{ fontSize:typographyKPI }}
                    tickFormatter={(str) => numberSimplifier(str)}
                    label={{
                      value: t('Amounts'),
                      position: 'insideTop',  offset:-25
                    }}
                  />

                  <Bar
                    type="monotone"
                    dataKey="monto"
                    yAxisId="promedioId"
                    strokeWidth={1}
                    stroke="#CF7100 "
                    fill="#FFB45B"
                    name={t('Total amount')}
                    //  label={<CustomBarLabel selectedTimeFrame={selectedTimeFrame}/>}
                  />
                  <Line
                    type="linear"
                    dataKey="promedio"
                    yAxisId="promedioId"
                    stroke="#005E6F"
                    strokeWidth={3}
                    name={t('Average amount')}
                  />
                  <Legend verticalAlign='bottom' height={1} align='right'/>
                  <Tooltip
                    content={(props) => (
                      <CustomTooltip timeframe={selectedTimeFrame} module={module} {...props} />
                    )}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ControlPanel;

// const CustomBarLabel = ({ x, y, value, selectedTimeFrame }) => {
//   return (
//     <text
//       x={selectedTimeFrame.value ==="maximum" ? x+60 : x-10}
//       y={y}
//       dy={-15}  // Ajusta el margen superior según tus necesidades
//       dx={1}
//       fontSize={15}  // Ajusta el tamaño de la fuente según tus necesidades
//       fontWeight="bold"
//       fill="gray"
//       textAnchor="middle"
//     >
//       {(value !== 0 && value < 700000) ? `$${value}` : null}
//     </text>
//   );
// };

const CustomTooltip = ({ active, payload, label, timeframe, module }) => {
  const [t] = useTranslation();
  const date = label?.split('/');
  if (active && payload && payload.length) {
    return (
      <Card
        sx={{
          flexShrink: 0,
          p: 1,
          paddingBottom: 0,
        }}
      >
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={
            timeframe.value === '7 day' || timeframe.value === '1 month'
              ? `${t(`m-${date[1]}`)} ${date[0]}, ${date[2]}`
              : `${t(`m-${date[0]}`)} ${date[1]}`
          }
          subheader={`${
            module === modules.load ? t('Plans.Loads') : t('Fleet.My-Travels.Travels')
          }: ${payload[0].payload.cantidad}`}
        />
        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ padding: 0, margin: 0, marginBottom: -2 }}>
          <Box
            sx={{
              backgroundColor: 'white',
              marginTop: 1,
              borderLeft: 6,
              borderLeftColor: payload[0].color,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              paddingLeft: 0.5,
            }}
          >
            <Typography>{`${t('Total-amount')}: $${payload[0].value}`}</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: 'white',
              marginTop: 1,
              borderLeft: 6,
              borderLeftColor: payload[1].color,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              paddingLeft: 0.5,
            }}
          >
            <Typography>{`${
              module === modules.load
                ? t('Average-amount-per-load')
                : t('Average-amount-per-travel')
            }: $${
              Number.isInteger(payload[1].value)
                ? payload[1].value.toString()
                : payload[1].value.toFixed(1)
            }`}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }
  return null;
};


