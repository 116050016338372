export const numberSimplifier = (str) => {
  if (str >= 1000000000000)
    return Number.isInteger(str / 1000000000000)
      ? `${(str / 1000000000000).toString()}B`
      : `${(str / 1000000000000).toFixed(1)}B`;
  if (str >= 1000000)
    return Number.isInteger(str / 1000000)
      ? `${(str / 1000000).toString()}M`
      : `${(str / 1000000).toFixed(1)}M`;
  if (str >= 1000)
    return Number.isInteger(str / 1000)
      ? `${(str / 1000).toString()}K`
      : `${(str / 1000).toFixed(1)}K`;
  return Number.isInteger(str) ? str.toString() : str.toFixed(1);
}

export const dateFormatter = (str,selectedTimeFrame, t) => {
  const strArray = str.split('/');
  const withoutZero = strArray[0][0] === '0' ? strArray[0].slice(1) : strArray[0];

  if (selectedTimeFrame.value === '7 day') {
    if (parseInt(withoutZero, 10) % 1 === 0)
      return ` ${t(`m-${strArray[1]}`)} ${strArray[0]}, ${strArray[2]} `;
    return '';
  }
  if (selectedTimeFrame.value === '1 month') {
    if (parseInt(withoutZero, 10) % 1 === 0)
      return ` ${t(`m-${strArray[1]}`)} ${strArray[0]}, ${strArray[2]} `;
    return '';
  }
  if (selectedTimeFrame.value === '1 year') {
    if (parseInt(withoutZero, 10) % 1 === 0)
      return ` ${t(`m-${strArray[0]}`)} ${strArray[1]} `;
    return '';
  }
  if (parseInt(withoutZero, 10) % 1 === 0) return ` ${t(`m-${strArray[0]}`)} ${strArray[1]} `;
  return '';
}


