import React, { useContext, useEffect, useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from '@mui/material';


const TimeFrame = ({key, timeFrame, selectedTimeFrame, handleOnChangeTimeFrame}) => {
    const theme = useTheme();
    return (
    <BottomNavigation
    key={`timeFrame-${key}`}
    showLabels
    value={selectedTimeFrame}
    onChange={(event, newValue) => handleOnChangeTimeFrame(newValue)}
    sx={{
    display: 'flex',
    flexDirection: { xs: 'row', sm: 'row' },
    height: 'auto',
    justifyContent: 'center',
    }}
    >
    {timeFrame?.map((tf, index) => (
    <BottomNavigationAction
        key={`timeFrame-${key}-index`}
        disabled={tf.disabled}
        value={index}
        label={tf.label}
        sx={{
        '.MuiBottomNavigationAction-label': {
            fontSize: 15,
            color:
            tf.disabled? '#CDCDCD':
            selectedTimeFrame.value === tf.value
                ? theme.palette.primary.main
                : '#000',
        },
        textAlign: 'center',
        width: { xs: '100%', md: 300 },
        borderBottom: 3,
        borderTopLeftRadius: index === 0 ? 10 : 0,
        borderBottomLeftRadius: index === 0 ? 10 : 0,
        borderTopRightRadius: index === 3 ? 10 : 0,
        borderBottomRightRadius: index === 3 ? 10 : 0,
        borderColor:
            selectedTimeFrame.value === tf.value ? theme.palette.primary.main : '#FFF',
        position: 'relative',
        transition: 'border-color 0.2s ease-in-out',
        }}
    />
    ))}
    </BottomNavigation>
    )
}

export default TimeFrame;
