import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Line,
  Bar,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../constants/colors';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';
import {
  sevenDaysOrderData,
  lastMonthOrderData,
  lastYearOrderData,
  maxOrderData,
} from '../../../components/controlPanels/utils/handleDataToTimeFrame';
import { timeFrame } from '../../../components/controlPanels/utils/timesFrame';
import TimeFrame from '../../../components/controlPanels/components/TimeFrame';

const LoadsPerTime = ({ title, data, firstSession, module }) => {
  const [t] = useTranslation();
  const {
    padMarByScreen,
    heightCardHeader,
    heightScreen,
    sizeFontCardHeader,
    mMainCard,
  } = useContext(ScreenSizeContext);
  const [colores, setColores] = useState(colors);
  const [tF7D, setTF7D] = useState([]);
  const [tF1M, setTF1M] = useState([]);
  const [tF1A, setTF1A] = useState([]);
  const [tFMax, setTFMax] = useState([]);
  const [dataToGraph, setDataToGraph] = useState([]);
  const copyTimeFrame = timeFrame.map(frame => ({ ...frame }))
  const [timeFrameState, setTimeFrameState] = useState();
  const [selectedTimeFrame, setSelecTimeFrame] = useState();

  const getColor = (count) => {
    const randomColors = [];
    const caracteresHex = '0123456789ABCDEF';

    for (let i = 0; i < count; i++) {
      let color = '#';
      for (let j = 0; j < 6; j++) {
        color += caracteresHex[Math.floor(Math.random() * 16)];
      }
      randomColors.push(color);
    }

    setColores([...colores, randomColors]);
  };

  const handleOnChangeTimeFrame = (newValue) =>{
    setSelecTimeFrame(copyTimeFrame[newValue]);
    setDataToGraph(
        newValue === 0 ? tF7D : newValue === 1 ? tF1M : newValue === 2 ? tF1A : tFMax,
    );
  }

  useEffect(() => {
    if (data && data.length > 0) {
      getColor(Object.keys(data[0]).length - 1);

      const week= sevenDaysOrderData(data);
      if (week?.length>0){
        setTF7D(week);
        copyTimeFrame[0].disabled=false
      }

      const month = lastMonthOrderData(data);
      if (month?.length>0){
        setTF1M(month);
        copyTimeFrame[1].disabled=false
      } 

      const year = lastYearOrderData(data);
      if (year?.length>0){
        setTF1A(year);
        copyTimeFrame[2].disabled=false
      }

      const max = maxOrderData(data, firstSession);
      if (max?.length>0){
        setTFMax(max);
        copyTimeFrame[3].disabled=false
      }
      
      setSelecTimeFrame(month? copyTimeFrame[1]: copyTimeFrame[3])
      setDataToGraph(month || max);
      setTimeFrameState(copyTimeFrame);
    }
  }, [data]);

  return (
    <Card
      sx={{
        flexShrink: 0,
        m: mMainCard,
        mb: 1.5,
        p: 0,
      }}
    >
      <CardHeader
        sx={{ textAlign: 'center', padding: padMarByScreen, height: heightCardHeader }}
        titleTypographyProps={{ fontSize: sizeFontCardHeader }}
        title={title}
      />
      <Divider
        sx={{ backgroundColor: 'orange', marginBottom: heightScreen > 900 ? 3 : 0, mx: 2 }}
      />
      <CardContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12}>
            <Box justifyContent="center" mb={3}>
            <TimeFrame
                timeFrame={timeFrameState}
                selectedTimeFrame={selectedTimeFrame}
                handleOnChangeTimeFrame={handleOnChangeTimeFrame}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={12}>
            {dataToGraph?.length > 0 && (
              <ResponsiveContainer width="100%" height={350}>
                <ComposedChart data={dataToGraph} baseValue="dataMin">
                  <defs>
                    <linearGradient id="colorArea" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#47F13F" stopOpacity={0.5} />
                      <stop offset="70%" stopColor="white" stopOpacity={0.05} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickSize={10}
                    tickFormatter={(str) => {
                      const strArray = str.split('/');
                      const withoutZero =
                        strArray[0][0] === '0' ? strArray[0].slice(1) : strArray[0];

                      if (selectedTimeFrame.value === '7 day') {
                        if (parseInt(withoutZero, 10) % 1 === 0)
                          return ` ${t(`m-${strArray[1]}`)} ${strArray[0]}, ${strArray[2]} `;
                        return '';
                      }
                      if (selectedTimeFrame.value === '1 month') {
                        if (parseInt(withoutZero, 10) % 1 === 0)
                          return ` ${t(`m-${strArray[1]}`)} ${strArray[0]}, ${strArray[2]} `;
                        return '';
                      }
                      if (selectedTimeFrame.value === '1 year') {
                        if (parseInt(withoutZero, 10) % 1 === 0)
                          return ` ${t(`m-${strArray[0]}`)} ${strArray[1]} `;
                        return '';
                      }
                      if (parseInt(withoutZero, 10) % 1 === 0)
                        return ` ${t(`m-${strArray[0]}`)} ${strArray[1]} `;
                      return '';
                    }}
                  />
                  <YAxis
                    dataKey="cantidad"
                    yAxisId="cantidad"
                    axisLine={false}
                    tickLine={false}
                    tickSize={1}
                    label={{
                      value: t('Documentation.Pedidos'),
                      angle: -90,
                      position: 'insideBottomLeft',
                      offset: 10,
                    }}
                  />

                  <Bar
                    type="monotone"
                    dataKey="cantidad"
                    yAxisId="cantidad"
                    strokeWidth={1}
                    stroke="#CF7100 "
                    fill="#FFB45B"
                    name={t("Status.Entregados")}
                  />
                  <Legend verticalAlign="bottom" height={1} align="right" />
                  <Tooltip
                    content={(props) => (
                      <CustomTooltip timeframe={selectedTimeFrame} module={module} {...props} />
                    )}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LoadsPerTime;

const CustomTooltip = ({ active, payload, label, timeframe, module }) => {
  const [t] = useTranslation();
  const date = label?.split('/');
  if (active && payload && payload.length) {
    return (
      <Card
        sx={{
          flexShrink: 0,
          p: 1,
          paddingBottom: 0,
        }}
      >
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={
            timeframe?.value === '7 day' || timeframe?.value === '1 month'
              ? `${t(`m-${date[1]}`)} ${date[0]}, ${date[2]}`
              : `${t(`m-${date[0]}`)} ${date[1]}`
          }
        />
        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ padding: 0, margin: 0, marginBottom: -2 }}>
          <Box
            sx={{
              backgroundColor: 'white',
              marginTop: 1,
              borderLeft: 6,
              borderLeftColor: payload[0].color,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              paddingLeft: 0.5,
            }}
          >
            <Typography>
              {t('Documentation.Pedidos')}: {payload[0].payload.cantidad}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }
  return null;
};
