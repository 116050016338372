import axios from 'axios';
import { PROMOTIONS_PATH } from '../config';

export const getPromotionsList = async (type, search) => {
  if (search) {
    return axios.get(`${PROMOTIONS_PATH}/listado?search=${search}&tipo=${type}`);
  }
  return axios.get(`${PROMOTIONS_PATH}/listado?tipo=${type}`);
};

export const getPromotion = async (id) => {
  return axios.get(`${PROMOTIONS_PATH}/detalle/${id}`);
};

export const getPlanPromotionsPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${PROMOTIONS_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllPlanPromotionsWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${PROMOTIONS_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deletePromotion = async (id) => {
  return axios.delete(`${PROMOTIONS_PATH}/eliminar?id=${id}`);
};

export const updatePrices = async () => {
  return axios.put(`${PROMOTIONS_PATH}/actualizarMontos/`);
};

export const createPromotion = async (promotion) => {
  return axios.post(`${PROMOTIONS_PATH}/guardar/`, promotion);
};
