import React, { createContext, useEffect, useState } from 'react';

import { getOrderDetail } from '../../../../api/order';

const OsContext = createContext({});
export const OsProvider = ({ children }) => {
  const [wayPointsToEdit, setWayPointsToEdit] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalPoints, setOriginalPoints] = useState(null);
  const [wayPoints, setWayPoints] = useState(null);
  const [carga, setCarga] = useState(null);

  // logica traer informacion de carga
  async function loadOrder(id) {
    try {
      const { data: order } = await getOrderDetail(id);
      setCarga(order);
      setOriginalPoints(order?.wayPoints);
      if (order.wayPoints[0].llegadaEstimada) {
        setStartDate(new Date(order.wayPoints[0].llegadaEstimada));
      }
    } catch (e) {
      console.log(e);
    }
  }

  // logica waypoint
  const getwayPoints = () => {
    const puntos = wayPointsToEdit?.map((waypoint, index) => {
      return {
        direccion: waypoint.address,
        id: waypoint?.id,
        idUbicacion: waypoint?.idUbicacion,
        idCarga: carga.id,
        latitud: waypoint.lat,
        longitud: waypoint.lng,
        orden: index,
        tiempoTransito: index === 0 ? null : routeInfo?.[index - 1]?.durationText,
        llegadaEstimada: index === 0 ? startDate : routeInfo?.[index - 1]?.tiempoEstimado,
        llegadaEstimadaStr: index === 0 ? startDate : routeInfo?.[index - 1]?.tiempoEstimado,
        tiempoServicio: waypoint?.timePuntoControl || null,
        tiempos:
          index === 0
            ? null
            : (routeInfo?.[index - 1]?.tiempoAdicional.length > 0 &&
                routeInfo?.[index - 1]?.tiempoAdicional?.map((tiempo) => ({
                  descripcion: tiempo.descripcion,
                  tiempoServicio: tiempo.tiempoServicio,
                  idWaypoint: waypoint?.id || null,
                  id: tiempo?.id || null,
                }))) ||
              null,
        wp:
          waypoint?.type === 'PUNTO DE CONTROL'
            ? null
            : (() => {
                const matchingWp = originalPoints.find((wp) => {
                  return wp.id === waypoint.id;
                });
                return matchingWp ? matchingWp.wp : null;
              })(),
      };
    });
    setWayPoints(puntos);
    setEndDate(routeInfo?.[routeInfo.length - 1]?.tiempoEstimado || null);
  };

  useEffect(() => {
    getwayPoints();
  }, [wayPointsToEdit, routeInfo, startDate]);

  const data = {
    wayPointsToEdit,
    setWayPointsToEdit,
    routeInfo,
    setRouteInfo,
    startDate,
    setStartDate,
    setOriginalPoints,
    wayPoints,
    endDate,
    loadOrder,
    carga,
  };

  return <OsContext.Provider value={data}>{children}</OsContext.Provider>;
};

export default OsContext;
