import React, { useState } from 'react';
import { Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { responderPregunta } from '../../../../../api/preguntaMarket';

const ResponderUna = ({ question, onClose, order, loadOrder, authenticatedData }) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [answer, setAnswer] = useState('');

  function handleChange(e) {
    const { value } = e.target;
    setAnswer(value);
  }

  const actionResponder = async () => {
    const pregunta = { ...question };

    pregunta.respuesta.push({
      alta: '',
      idRemitente: authenticatedData?.id,
      remitente: '',
      mensaje: answer,
    });
    try {
      await responderPregunta(pregunta, order.id);
      await loadOrder();
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 4000,
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ml: { xs: 0.5, sm: 1 },
          mr: { xs: 0.5, sm: 1 },
          mb: 1,
          width: { xs: '100%', sm: 'auto' }, // Ajuste dinámico
          minWidth: { xs: '100%', sm: 560 }, // Para pantallas grandes
          maxWidth: '100%',
          minHeight: 100,
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mb: -1, mr: -1 }}
        >
          <IconButton onClick={onClose} sx={{ mr: '0.5rem' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            p: 1,
            borderRadius: '16px 16px 16px 16px',
          }}
        >
          {question.respuesta.length < 1 && (
            <>
              <Typography variant="h5" id="responderUna">
                {question.nombreRemitente}
              </Typography>
              <Typography id="responderUna">{question.pregunta}</Typography>
              <Divider sx={{ mt: 1, mb: 1 }} />
            </>
          )}
          <Typography id="responderUna" variant="h4" mb={1}>
            {question.respuesta.length < 1
              ? t('Marketplace.Chat.ReplyHelperLabel')
              : t('Marketplace.Chat.NewHelperLabel')}
          </Typography>
          <TextField
            id={`respuesta ${question.id}`}
            multiline
            rows={3}
            value={answer}
            onChange={handleChange}
            sx={{
              width: '100%',
              borderColor: (theme) => theme.palette.primary.main,
              backgroundColor: 'default',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            sx={{ mr: '0.5rem' }}
            size="small"
            variant="contained"
            disabled={answer.length === 0}
            onClick={() => {
              onClose();
              actionResponder();
            }}
          >
            <Typography variant="body1">
              {question.respuesta.length < 1
                ? t('Marketplace.Chat.ReplyMessage')
                : t('Marketplace.Chat.SendMessage')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ResponderUna;
