import axios from 'axios';
import { PERMISSIONS_PATH, PERMISSIONS_SUBCATEGORIES_PATH } from '../config';

export const getPermissions = async () => {
  return axios.get(`${PERMISSIONS_PATH}/listado`);
};

export const createPermission = async (permission) => {
  return axios.post(`${PERMISSIONS_PATH}/guardar`, permission);
};

export const getPermissionsCategory = async () => {
  return axios.get(`${PERMISSIONS_PATH}/listado/categoria`);
};

export const getPermissionsMap = async () => {
  return axios.get(`${PERMISSIONS_PATH}/listado-map`);
};

export const getPermissionPaged = async (size, page, sortType1, direction) => {
  return axios.get(
    `${PERMISSIONS_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};
export const getAllPermissionsWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType1,
  direction,
) => {
  return axios.get(
    `${PERMISSIONS_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType1},${direction.toUpperCase()}`,
  );
};

export const deletePermission = async (id) => {
  return axios.delete(`${PERMISSIONS_PATH}/eliminar?id=${id}`);
};

export const getPermissionSubCategories = async () => {
  return axios.get(`${PERMISSIONS_SUBCATEGORIES_PATH}/listado`);
};
