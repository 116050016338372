import axios from 'axios';
import { DOCUMENTS_PATH, LIST_DOCUMENTS_PATH, DOCUMENT_TYPES_PATH } from '../config';
import { reqConf, reqErr, resErr, resFilter } from '../config/interceptor';

axios.interceptors.request.use(reqConf, reqErr);
axios.interceptors.response.use(resFilter, resErr);

export const getDocuments = async () => {
  return axios.get(`${DOCUMENTS_PATH}`);
};
axios.defaults.headers.common.Authorization = `${localStorage.getItem('accessToken')}`;

export const getAccessories = async () => {
  return axios.get(`${DOCUMENTS_PATH}/listado`);
};

export const createAccessorie = async (accessorie) => {
  return axios.post(`${DOCUMENTS_PATH}/guardar`, accessorie);
};

export const getListDocuments = async (query) => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listado?searchParam=${query}`);
};

export const getDocumentTypes = async () => {
  return axios.get(`${DOCUMENT_TYPES_PATH}/listado`);
};

export const getDocumentsOfCarga = async (idCarga) => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listar/carga?id=${idCarga}`);
};

export const getDocumentsOfPedido = async (idCarga) => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listar/pedido?id=${idCarga}`);
};

export const getDocumentsOfViaje = async (idCarga) => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listar/viaje?id=${idCarga}`);
};

export const getDocumentsOfActivo = async (type, idActivo) => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listado/activo?tipo=${type}&id=${idActivo}`);
};

export const createDocument = async (query, formData) => {
  return axios.post(`${LIST_DOCUMENTS_PATH}/nuevo?${query}`, formData);
};

export const createDocumentType = async (dt) => {
  return axios.post(`${DOCUMENT_TYPES_PATH}/guardar`, dt);
};

export const ViewAttachment = (query) => {
  return `${LIST_DOCUMENTS_PATH}/ver-archivo?path=${encodeURIComponent(query)}`;
};

export const updateDocument = async (query, formData) => {
  return axios.put(`${LIST_DOCUMENTS_PATH}/editar?${query}`, formData);
};

export const removeAttachment = async (id, file) => {
  return axios.get(
    `${LIST_DOCUMENTS_PATH}/eliminar/archivo?idDocumentacion=${id}&archivo=${encodeURIComponent(
      file,
    )}`,
  );
};

export const deleteDocument = async (id) => {
  return axios.delete(`${LIST_DOCUMENTS_PATH}/eliminar?id=${id}`);
};

export const deleteDocumentType = async (id) => {
  return axios.delete(`${DOCUMENT_TYPES_PATH}/eliminar?id=${id}`);
};

export const getCompanyDocuments = async () => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listado/empresa`);
};

export const getCompanyDocumentsFleet = async () => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listado/empresa/flota`);
};

export const deleteFolder = async (idEntity, entityType) => {
  return axios.delete(
    `${LIST_DOCUMENTS_PATH}/eliminar-carpeta?idEntidad=${idEntity}&tipoEntidad=${entityType}`,
  );
};

export const getCompanyProfileDocuments = async () => {
  return axios.get(`${LIST_DOCUMENTS_PATH}/listado/documentacion-empresa`);
};

export const getDocumentTypesListPaged = async (size, page, sortType, direction) => {
  return axios.get(
    `${DOCUMENT_TYPES_PATH}/listado-page?size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}`,
  );
};

export const getAllDocumentTypesWithQueryAndPagination = async (
  searchParam,
  size,
  page,
  sortType,
  direction,
) => {
  return axios.get(
    `${DOCUMENT_TYPES_PATH}/listado-page?searchParam=${encodeURIComponent(
      searchParam,
    )}&size=${size}&page=${page}&sort=${sortType},${direction.toUpperCase()}`,
  );
};
