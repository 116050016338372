import { useTranslation } from 'react-i18next';
import fleet from '../assets/images/welcome/flota.png';
import marketplace from '../assets/images/welcome/marketplace.png';
import workflow from '../assets/images/welcome/iworkflow.png';
import cargo from '../assets/images/welcome/cargas.png';
import control from "../assets/images/welcome/control.png"
import bay from "../assets/images/welcome/bay.png";
import { modules } from '../constants/permissions';
import { CheckModulesShortcutt } from '../utils/relatedToPermissions';
import useAuth from '../hooks/useAuth';

const ShortcutItems = () => {
  const [t] = useTranslation();
  const auth = useAuth();

  return [
    {
      path: `/torre-de-control`,
      title: t('Icons.Control-tower'),
      icon: control,
      modulo: modules.controlTower,
      disabled: !CheckModulesShortcutt(modules.controlTower, auth),
    },
    {
      path: `/carga`,
      title: t('Icons.Loads'),
      icon: cargo,
      modulo: modules.load,
      disabled: !CheckModulesShortcutt(modules.load, auth),
    },
    {
      path: `/flota`,
      title: t('Icons.Fleet'),
      icon: fleet,
      modulo: modules.fleet,
      disabled: !CheckModulesShortcutt(modules.fleet, auth),
    },
    {
      path: `/marketplace`,
      title: t('Icons.Marketplace'),
      icon: marketplace,
      modulo: modules.marketplace,
      disabled: !CheckModulesShortcutt(modules.marketplace, auth),
    },
    {
      path: `/workflow`,
      title: t('Icons.Workflow'),
      icon: workflow,
      modulo: modules.workflow,
      disabled: !CheckModulesShortcutt(modules.workflow, auth),
    },
    {
      path: '/bahia',
      title: t('Icons.Bahia'),
      icon: bay,
      modulo: modules.bay,
      disabled: !CheckModulesShortcutt(modules.bay, auth),
    },
    {
      path: '/configuracion',
      title: t('Icons.Configuration'),
      icon: 'settings',
      isFeatherIcon: true,
    },
    {
      title: t('Icons.Logout'),
      icon: 'log-out',
      isFeatherIcon: true,
      isLogout: true,
      disabled: false,
    },
  ];
};
export default ShortcutItems;
