import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutStripe from "./CheckoutStripe";
import { createSuscStripe } from "../../api/subscription";

const stripePromise = loadStripe('pk_live_51NXR5HFXdJ7UL9VdldxFdMVcc9mXTVZ2TCFmp7Ad5gA5oZwPP4m3sazaTyyGWfU4MGFdEd9iieygiNB2DFG2JAJ800u0bEWgih');

export default function ViewCheckoutStrpe({plan, formik = null, formEmpresa = null}) {
  
  const [clientSecret, setClientSecret] = useState("");
  const [form, setForm] = useState(formEmpresa)

  useEffect(() => {
    const init = async() => {
        if(plan.priceIdStripe) {
          createSuscStripe(plan.priceIdStripe, {
            idPromocion: plan.id,
            empresa:{
                nombre: form === null ? formik.values.nombreEmpresa:form.nombreEmpresa,
                mail: form === null ? formik.values.mailEmpresa:form.mailEmpresa
            }
        })
          .then((res) => {
            setClientSecret(res.data.clientSecret)
            if(form !== null) {
              setForm({...form, stripeSubscId:res.data.subscriptionId})
            } else {
              formik.setFieldValue("stripeSubscId", res.data.subscriptionId)
            }
          })
      };
    }
    init()
  }, [plan])

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutStripe plan={plan} formik = {formik} form = {form}/>
        </Elements>
      )}
    </div>
  );
}
