import { Box, Rating, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableCard from '../../../../components/table-card/TableCard';
import ScoreHead from './ScoreHead';

const TablePedidos = ({ rows }) => {
  const [t] = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('nroCarga');
  const [filtro, setFiltro] = useState(false);
  const [score, setScore] = useState(0);

  const handleRequestSort = (event, property) => {
    const isASC = orderBy === property && order === 'asc';
    setOrder(isASC ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableCard custompadding="0px" customMargin="0px">
        <Box sx={{ overflow: 'auto' }}>
          <TableContainer aria-label="Calificación">
            <Table>
              <ScoreHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                filtro={filtro}
                esPedido
              />
              {rows && rows.length !== 0 ? (
                rows.some((row) => row.pedidos.length !== 0) ? (
                  rows.map((row) =>
                    row.pedidos.map((item) => (
                      <TableRow hover key={item.id}>
                        <TableCell sx={{ borderBottom: '0' }}>
                          <Typography variant="h5" fontWeight="600">
                            {item.nroPedido || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: '0' }}>
                          <Typography variant="h5" fontWeight="600">
                            {item?.nombreCliente || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: '0' }}>
                          <Typography color="textSecondary" variant="h5" fontWeight="600">
                            {item.descripcion || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '0', zIndex: 1000 }}>
                          <Rating
                            name="simple-controlled"
                            value={item.calificacionesCliente}
                            readOnly
                          />
                        </TableCell>
                      </TableRow>
                    )),
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="h4" color="textPrimary">
                      {t("Fleet.Home.Assets-travels.Without-results")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="h4" color="textPrimary">
                      {t('Fleet.Home.Assets-travels.Without-results')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
        </Box>
      </TableCard>
    </>
  );
};

export default TablePedidos;
