import axios from 'axios';
import { KPI_CUSTOM_PATH, KPI_ENUM_PATH } from '../config';

export const getDataList = async () => {
  return axios.get(`${KPI_ENUM_PATH}/tipo-data`);
};

export const getAxisList = async () => {
  return axios.get(`${KPI_ENUM_PATH}/tipo-eje`);
};

export const createKPI = async (data) => {
  return axios.post(`${KPI_CUSTOM_PATH}/guardar/`,data);
}

export const getCreatedKPI = async () => {
  return axios.get(`${KPI_CUSTOM_PATH}/listadoPorEmpresa/`);
}

export const getKPIbyId = async (id) => {
  return axios.get(`${KPI_CUSTOM_PATH}/buscarPorId?id=${id}`)
}

export const deleteKPI = async (id) => {
  return axios.delete(`${KPI_CUSTOM_PATH}/eliminar?id=${id}`);
};
