import {
    Box,
    Grid,
    IconButton,
    LinearProgress,
    Rating,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Button,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { useParams, useNavigate, useLocation } from 'react-router';
  import moment from 'moment';
  import { useTranslation } from 'react-i18next';
  import { useSnackbar } from 'notistack';
  import PageContainer from '../../../components/container/PageContainer';
  import Breadcrumb from '../../../layouts/fleet-layout/breadcrumb/Breadcrumb';
  import { getOtDetails } from '../../../api/viaje';
  import CargoHead from './tables/CargoHead';
  import TableCard from '../../../components/table-card/TableCard';
  import { createScore } from '../../../api/score';
  import TableScore from './tables/TableScore';
  import TablePedidos from './tables/TablePedidos';
  
  const LoadScore = () => {
    const [t] = useTranslation();
    const { id: orderId } = useParams();
    const [travel, setTravel] = useState(null);
    const [score, setScore] = useState(null);
    const [cargas, setCargas] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('nroCarga');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
  
    const location = useLocation();
  
    function getModule() {
      return location.pathname.split('/')[1];
    }
  
    const { cargaId } = location.state || {};
  
    const isCarga = getModule() === 'carga';
    const currentModule = isCarga ? 'carga' : 'flota';
  
    const BCrumb = [
      {
        to: '/',
        title: t('Fleet.My-Travels.Details.Home'),
      },
      {
        to: '/flota/viajes',
        title: t('Fleet.My-Travels.Details.List'),
      },
      {
        title: 'Calificaciones',
      },
    ];
    const handleRequestSort = (event, property) => {
      const isASC = orderBy === property && order === 'asc';
      setOrder(isASC ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const getTravel = async () => {
      const { data } = await getOtDetails(orderId);
      setTravel(data);
      setCargas(data.cargas);
    };

    const showMessage = (message, variant) => {
      enqueueSnackbar(message, {
        variant,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 4000,
      });
    };
  
    const scoring = async (value, carga) => {
      try {
        const { data } = await createScore({
          descripcion: '',
          idCarga: cargaId || carga.id,
          idEmpresaOrigen: carga.idEmpresaTransportista, // ES EL CALIFICADOR 
          idEmpresaDestino: carga.idEmpresaCreadora,
          idViaje: travel.id,
          puntos: value,
        });
        setScore(data);
        showMessage('Calificado correctamente', 'success');
        getTravel();
      } catch (error) {
        console.log(error.message);
        // showMessage('Error', 'error');
      }
    };
  
    useEffect(() => {
      getTravel();
    }, []);
  
    return (
      <PageContainer title="Calificación" description="Calificación de cargas">
        <Breadcrumb items={BCrumb} />
        <Grid sx={{ marginBottom: '5rem', marginTop: '4rem' }}>
          <TableCard
            title={<Typography variant="h1">{t('ModalScore.ModalTitle')}</Typography>}
            custompadding="0px"
            customMargin="0px"
          >
            <Box sx={{ overflow: 'auto' }}>
              <TableContainer>
                <Table
                  aria-label="Calificación"
                  sx={{
                    whiteSpace: {
                      xs: 'nowrap',
                      sm: 'unset',
                    },
                  }}
                >
                  <CargoHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    module={currentModule}
                  />
                  {cargas &&
                      cargas?.length !== 0 &&
                      cargas?.map((row) => (
                        <TableRow hover key={row.id}>
                          <TableCell sx={{ borderBottom: '0' }}>
                            <Typography variant="h5" fontWeight="600">
                              {row?.nroCarga || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: '0' }}>
                            <Typography color="textSecondary" variant="h5" fontWeight="600">
                              {row?.descripcion || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: '0' }}>
                            <Typography color="textSecondary" fontWeight="200">
                              {moment(row?.periodoDeDescarga?.fechaFin).format('DD/MM/YYYY HH:mm') ||
                                '---'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: '0' }}>
                            <Typography color="textPrimary" variant="h5" fontWeight="600">
                              {row?.estadoViajeNombre || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ borderBottom: '0', zIndex: 1000 }}>
                            <Rating
                              name="simple-controlled"
                              value={
                                row?.califTransportista?.puntos ||
                                0
                              }
                              onChange={(event, newValue) => {
                                scoring(newValue, row);
                              }}
                              readOnly={
                                row?.califTransportista?.puntos
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </Table>
              </TableContainer>
            </Box>
          </TableCard>
        </Grid>
        <Grid>
          <TableScore rows={travel?.califDadores} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} align="left">
            <Button
              size="small"
              // fullWidth={!mdUp}
              onClick={() => navigate(-1)}
              sx={{
                width: '200px',
                mt: 2,
                mb: 2,
              }}
              variant="contained"
              color="error"
            >
              <Typography variant="h3">{t('Button.Back')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </PageContainer>
    );
  };
  
  export default LoadScore;
  