import React, { useContext } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  LineChart,
  Line,
  AreaChart,
  RadialBarChart,
  RadialBar,
  PieChart,
  RadarChart,
  FunnelChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Pie,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Funnel,
  LabelList,
  Cell,
  Tooltip,
  Legend,
  Label,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';
import lineaRecta from '../../../assets/images/icons/graficos-KPI/lineaRecta.png';
import { dateFormatter, numberSimplifier } from '../utils/handleTickFormatter';
import ActionsListTable from '../../table-card/ActionsListTable';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#b71c1c',
  '#194d33',
  '#e91e63',
  '#607d8b',
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.15;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="center">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const calcularTickCount = (data) => {
  if (!Array.isArray(data)) {
    console.error('La variable "data" no es una matriz.');
    return 0;
  }

  const maxValue = Math.max(...data?.map((item) => item.cantidad));
  return maxValue > 4 ? 5 : maxValue + 1;
};

const getSortDesc = (list) => {
  return list?.slice().sort((a, b) => b.cantidad - a.cantidad);
};

const LiveGraphPreview = ({
  id,
  title,
  chartType,
  data,
  mainDataKey,
  dataGrouperKey,
  yAxisLabel,
  xAxisLabel,
  showVerticalGrid = false,
  showHorizontalGrid = false,
  color,
  timeFrameComponent = null,
  selectedTimeFrame,
  actions = null,
  legendContend,
  atribute
}) => {
  const [t] = useTranslation();
  const {
    mMainCard,
    padMarByScreen,
    heightCardHeader,
    sizeFontCardHeader,
    typographyKPI,
    widthScreen,
    heightKPI,
  } = useContext(ScreenSizeContext);
  const labelX = !atribute ? t(`${xAxisLabel}`) : t(`${xAxisLabel}: ${atribute}`);
  const labelY = t(`${yAxisLabel}`);
  const tickCount = calcularTickCount(data);
  const dataSortedDesc = getSortDesc(data);
  const dateTypes =
    xAxisLabel === 'Inicio' ||
    xAxisLabel === 'Finalización' ||
    xAxisLabel === 'Creación' ||
    yAxisLabel === 'Inicio' ||
    yAxisLabel === 'Finalización' ||
    yAxisLabel === 'Creación';

  let ChartComponent;
  let ElementChartComponent;
  const sharedCharts =
    chartType === 'LINEA_RECTA' ||
    chartType === 'LINEA_CURVA' ||
    chartType === 'AREA_RECTA' ||
    chartType === 'AREA_CURVA' ||
    chartType === 'BARRA_VERTICAL' ||
    chartType === 'BARRA';

  const handleTickFormatter = (str) => {
    if (dateTypes && selectedTimeFrame) {
      return dateFormatter(str, selectedTimeFrame, t);
    }
    return t(`${str.charAt(0).toUpperCase() + str.slice(1)}`);
  };

  switch (chartType) {
    case 'LINEA_RECTA':
      ChartComponent = LineChart;
      ElementChartComponent = <Line type="linear" dataKey={mainDataKey} stroke={color} />;
      break;
    case 'LINEA_CURVA':
      ChartComponent = LineChart;
      ElementChartComponent = <Line type="monotone" dataKey={mainDataKey} stroke={color} />;
      break;
    case 'AREA_RECTA':
      ChartComponent = AreaChart;
      ElementChartComponent = (
        <Area
          type="linear"
          dataKey={mainDataKey}
          stroke={color}
          fillOpacity={1}
          fill={`url(#colorArea${id})`}
        />
      );
      break;
    case 'AREA_CURVA':
      ChartComponent = AreaChart;
      ElementChartComponent = (
        <Area
          type="monotone"
          dataKey={mainDataKey}
          stroke={color}
          fillOpacity={1}
          fill={`url(#colorArea${id})`}
        />
      );
      break;
    case 'BARRA_RADIAL':
      ChartComponent = RadialBarChart;
      ElementChartComponent =
        color !== 'multicolor' ? (
          <RadialBar
            dataKey={mainDataKey}
            minAngle={15}
            background
            label={{ fill: '#666', position: 'insideStart' }}
            clockWise
            fill={color}
            stroke={color}
          />
        ) : (
          <RadialBar
            dataKey={mainDataKey}
            minAngle={15}
            background
            label={{ fill: '#666', position: 'insideStart' }}
            clockWise
            fill={color}
            stroke={color}
          >
            {data?.map((entry, i) => (
              <Cell key={`cell-${entry.id}`} fill={COLORS[i % COLORS?.length]} />
            ))}
          </RadialBar>
        );
      break;
    case 'BARRA':
      ChartComponent = BarChart;
      ElementChartComponent = <Bar dataKey={mainDataKey} barSize={40} fill={color} />;
      break;
    case 'TORTA':
      ChartComponent = PieChart;
      ElementChartComponent =
        color !== 'multicolor' ? (
          <Pie
            data={data}
            dataKey={mainDataKey}
            cx="50%"
            cy="50%"
            outerRadius="80%"
            fill={color}
            label={renderCustomizedLabel}
          />
        ) : (
          <Pie
            data={data}
            dataKey={mainDataKey}
            cx="50%"
            cy="50%"
            outerRadius="80%"
            fill={color}
            label={renderCustomizedLabel}
          >
            {data?.map((entry, i) => (
              <Cell key={`cell-${entry.id}`} fill={COLORS[i % COLORS?.length]} />
            ))}
          </Pie>
        );
      break;
    case 'RADAR':
      ChartComponent = RadarChart;
      ElementChartComponent = (
        <Radar dataKey={mainDataKey} stroke={color} fill={color} fillOpacity={0.6} />
      );
      break;
    case 'EMBUDO':
      ChartComponent = FunnelChart;
      ElementChartComponent =
        color !== 'multicolor' ? (
          <Funnel dataKey={mainDataKey} data={dataSortedDesc} isAnimationActive fill={color}>
            <LabelList
              position="right"
              stroke={color}
              dataKey={dataGrouperKey}
              formatter={handleTickFormatter}
            />
          </Funnel>
        ) : (
          <Funnel dataKey={mainDataKey} data={dataSortedDesc} isAnimationActive fill={color}>
            <LabelList
              position="right"
              stroke={color}
              dataKey={dataGrouperKey}
              formatter={handleTickFormatter}
            />
            {data?.map((entry, i) => (
              <Cell key={`cell-${entry.id}`} fill={COLORS[i % COLORS?.length]} />
            ))}
          </Funnel>
        );
      break;
    default:
      ChartComponent = BarChart;
      ElementChartComponent = <Bar dataKey={mainDataKey} barSize={40} fill={color} />;
  }

  return (
    <Card sx={{ flexShrink: 0, m: mMainCard, mb: 1.5, p: 0, pb: 0 }}>
      <Grid container xs={12} direction="row" justifyContent="center">
        <Grid item xs={11.5} alignItems="end">
          <CardHeader
            sx={{ textAlign: 'center', padding: padMarByScreen, height: heightCardHeader }}
            titleTypographyProps={{ fontSize: sizeFontCardHeader, color: title ? 'black' : 'gray' }}
            title={title || t('Title')}
          />
        </Grid>
        {actions && (
          <Grid item xs={0.5} container justifyContent="end">
            <ActionsListTable
              handleDelete={actions?.handleConfirmDelete}
              handleEdit={actions?.handleEdit}
            />
          </Grid>
        )}
      </Grid>
      <Divider sx={{ backgroundColor: 'orange', marginBottom: 0, mx: 2 }} />
      <CardContent sx={{ ml: 0, mb: -2 }}>
        {timeFrameComponent && (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={12}>
              <Box justifyContent="center" mb={3}>
                {timeFrameComponent}
              </Box>
            </Grid>
          </Grid>
        )}
        {!chartType && (
          <Grid container justifyContent="center" alignItems="center">
            <img
              src={lineaRecta}
              alt="Curva"
              style={{
                marginLeft: 8,
                marginRight: -4,
                marginBottom: 20,
                height: '200px',
                opacity: 0.5,
              }}
            />
          </Grid>
        )}
        {data?.length > 0 && sharedCharts && (
          <ResponsiveContainer width="100%" height={chartType === 'BARRA' ? 460 : heightKPI}>
            <ChartComponent
              layout={chartType === 'BARRA' ? 'vertical' : 'horizontal'}
              data={data}
              margin={
                widthScreen > 450
                  ? { left: chartType === 'BARRA' ? 30 : 10, bottom: 0, top: 30 }
                  : { left: chartType === 'BARRA' ? 10 : 0, bottom: 0, top: 0 }
              }
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={showHorizontalGrid}
                vertical={showVerticalGrid}
              />
              {(chartType === 'AREA_RECTA' || chartType === 'AREA_CURVA') && (
                <defs>
                  <linearGradient id={`colorArea${id}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color} stopOpacity={0} />
                  </linearGradient>
                </defs>
              )}

              {chartType !== 'BARRA' ? (
                <XAxis
                  dataKey={dataGrouperKey}
                  type="category"
                  label={{ value: labelX, position: 'insideBottom', offset: -15 }}
                  tick={{ fontSize: typographyKPI }}
                  tickFormatter={handleTickFormatter}
                  tickMargin={7}
                />
              ) : (
                <XAxis
                  hide={widthScreen <= 450}
                  type="number"
                  dataKey={mainDataKey}
                  tickLine={false}
                  axisLine={false}
                  tick={{ fontSize: typographyKPI }}
                  label={{ value: labelX, position: 'insideBottom', offset: -15 }}
                  tickSize={1}
                  tickFormatter={(str) => numberSimplifier(str)}
                  tickCount={tickCount}
                  tickMargin={7}
                />
              )}

              {chartType !== 'BARRA' ? (
                <YAxis
                  hide={widthScreen <= 450}
                  type="number"
                  dataKey={mainDataKey}
                  tickLine={false}
                  axisLine={false}
                  tickSize={1}
                  tick={{ fontSize: typographyKPI }}
                  label={{ value: labelY, position: 'insideTop', offset: -25 }}
                  tickFormatter={(str) => numberSimplifier(str)}
                  tickCount={tickCount}
                />
              ) : (
                <YAxis
                  dataKey={dataGrouperKey}
                  type="category"
                  interval={0}
                  label={{ value: labelY, position: 'insideTop', offset: -25 }}
                  tick={{ fontSize: typographyKPI }}
                  tickFormatter={handleTickFormatter}
                />
              )}
              <Tooltip
                content={(props) => (
                  <CustomTooltipContent
                    chartType={chartType}
                    timeframe={selectedTimeFrame}
                    labelY={labelY}
                    labelX={labelX}
                    dateTypes={dateTypes}
                    {...props}
                  />
                )}
              />
              <Legend
                formatter={(value, entry, index) => t(`${legendContend}`)}
                align={chartType !== 'BARRA' ? 'right' : 'left'}
              />
              {ElementChartComponent}
            </ChartComponent>
          </ResponsiveContainer>
        )}
        {data && chartType === 'BARRA_RADIAL' && (
          <ResponsiveContainer width="100%" height={heightKPI}>
            <ChartComponent
              width={730}
              height={250}
              innerRadius="10%"
              outerRadius="80%"
              data={data}
              startAngle={180}
              endAngle={0}
            >
              {ElementChartComponent}
              <Legend
                iconSize={10}
                height={1}
                verticalAlign="middle"
                align="center"
                payload={data
                  .slice()
                  .reverse()
                  ?.map((entry, i) => ({
                    value: t(`${entry.seccion}`),
                    type: 'square',
                    color:
                      color !== 'multicolor'
                        ? color
                        : COLORS[(data?.length - 1 - i) % COLORS?.length],
                  }))}
              />
              <Tooltip
                content={(props) => (
                  <CustomTooltipContent
                    chartType={chartType}
                    timeframe={selectedTimeFrame}
                    labelY={labelY}
                    labelX={labelX}
                    dateTypes={dateTypes}
                    {...props}
                  />
                )}
              />
            </ChartComponent>
          </ResponsiveContainer>
        )}

        {data && chartType === 'TORTA' && (
          <ResponsiveContainer width="100%" height={heightKPI}>
            <ChartComponent margin={{ left: 20, right: 20 }}>
              {ElementChartComponent}
              <Tooltip
                content={(props) => (
                  <CustomTooltipContent
                    chartType={chartType}
                    timeframe={selectedTimeFrame}
                    labelY={labelY}
                    labelX={labelX}
                    dateTypes={dateTypes}
                    {...props}
                  />
                )}
              />
            </ChartComponent>
          </ResponsiveContainer>
        )}

        {data && chartType === 'RADAR' && (
          <ResponsiveContainer width="100%" height={heightKPI}>
            <ChartComponent
              outerRadius={
                widthScreen > 1400 || (widthScreen < 900 && widthScreen > 500) ? '80%' : '100%'
              }
              data={data}
            >
              <PolarGrid />
              {(widthScreen > 1400 || (widthScreen < 900 && widthScreen > 500)) && (
                <PolarAngleAxis
                  dataKey={dataGrouperKey}
                  tick={{ fontSize: typographyKPI }}
                  tickFormatter={handleTickFormatter}
                />
              )}
              <PolarRadiusAxis angle={45} />
              {ElementChartComponent}
              <Tooltip
                content={(props) => (
                  <CustomTooltipContent
                    chartType={chartType}
                    timeframe={selectedTimeFrame}
                    labelY={labelY}
                    labelX={labelX}
                    dateTypes={dateTypes}
                    {...props}
                  />
                )}
              />
              <Legend
                iconSize={10}
                align="center"
                formatter={(value, entry, index) => t(`${legendContend}`)}
              />
            </ChartComponent>
          </ResponsiveContainer>
        )}

        {data && chartType === 'EMBUDO' && (
          <ResponsiveContainer width="100%" height={heightKPI}>
            <ChartComponent margin={{ right: widthScreen > 450 ? 25 : 20 }}>
              {ElementChartComponent}
              <Tooltip
                content={(props) => (
                  <CustomTooltipContent
                    chartType={chartType}
                    timeframe={selectedTimeFrame}
                    labelY={labelY}
                    labelX={labelX}
                    dateTypes={dateTypes}
                    {...props}
                  />
                )}
              />
            </ChartComponent>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default LiveGraphPreview;

const CustomTooltipContent = ({
  active,
  payload,
  label,
  timeframe,
  labelY,
  labelX,
  chartType,
  dateTypes,
}) => {
  const [t] = useTranslation();
  const isVerticalBar = chartType === 'BARRA';

  const date = dateTypes && typeof label === 'string' ? label.split('/') : [];
  if (active && payload && payload?.length) {
    const data = payload[0]?.payload;
    let color;

    switch (chartType) {
      case 'EMBUDO':
      case 'TORTA':
        color = data?.fill;
        break;
      case 'BARRA_RADIAL':
        color = payload[0]?.color === 'multicolor' ? COLORS[label] : payload[0]?.color;
        break;
      default:
        color = payload[0]?.color;
    }

    return (
      <Card
        sx={{
          flexShrink: 0,
          p: 1,
          paddingBottom: 0,
        }}
      >
        <CardHeader
          sx={{ textAlign: 'center', margin: -2 }}
          titleTypographyProps={{ fontSize: 20 }}
          title={
            dateTypes && date.length >= 2
              ? timeframe.value === '7 day' || timeframe.value === '1 month'
                ? `${t(`m-${date[1]}`)} ${date[0]}, ${date[2] || ''}`
                : `${t(`m-${date[0]}`)} ${date[1]}`
              : t(`${data.seccion.charAt(0).toUpperCase() + data.seccion.slice(1)}`)
          }
        />

        <Divider sx={{ backgroundColor: 'orange', marginBottom: 1 }} />
        <CardContent sx={{ padding: 0, margin: 0, marginBottom: -2 }}>
          <Box
            sx={{
              backgroundColor: 'white',
              marginTop: 1,
              borderLeft: 6,
              borderLeftColor: color,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              paddingLeft: 0.5,
            }}
          >
            <Typography>{`${isVerticalBar ? labelX : labelY}: ${payload[0].value}`}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null;
};
