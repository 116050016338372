  import React, { useContext, useEffect, useState } from 'react';
  import { useNavigate } from 'react-router';
  import { useTranslation } from 'react-i18next';
  import { useSnackbar } from 'notistack';
  import { timeFrame } from '../utils/timesFrame';
  import { lastSevenDaysOwnKPI, lastMonthDataOwnKPI, lastYearDataOwnKPI, maxDataOwnKPI } from '../utils/handleDataToTimeFrame';
  import TimeFrame from '../components/TimeFrame';
  import LiveGraphPreview from '../components/LiveGraphPreview';
  import { deleteKPI } from '../../../api/kpi';
  import ModalConfirm from '../../modals/ModalConfirm';

  const KPIcreated = ({data, firstSession}) =>{
    console.log(data);
    const [t] = useTranslation();
    const navigate = useNavigate();
    const copyTimeFrame = timeFrame.map(frame => ({ ...frame }))
    const [timeFrameState, setTimeFrameState] = useState();
    const [selectedTimeFrame, setSelecTimeFrame] = useState();
    const [tF7D, setTF7D] = useState([]);
    const [tF1M, setTF1M] = useState([]);
    const [tF1A, setTF1A] = useState([]);
    const [tFMax, setTFMax] = useState([]);
    const [dataToGraph, setDataToGraph] = useState([]);
    // console.log(dataToGraph);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    

    const handleOnChangeTimeFrame = (newValue) =>{
      setSelecTimeFrame(copyTimeFrame[newValue]);
      setDataToGraph(
          newValue === 0 ? tF7D : newValue === 1 ? tF1M : newValue === 2 ? tF1A : tFMax,
      );
    }
    
    const handleCloseModal = () => {
      setOpenConfirmModal(false);
    }

    const handleEdit = () => {
      navigate(`/carga/kpi/${data.id}`);
    };

    const handleConfirmDelete = () => {
      setOpenConfirmModal(true);
    };

    const handleKPIdelete = async () => {
      setLoadingDelete(true);
      try {
        await deleteKPI(data.id);
        window.location.reload();
        enqueueSnackbar(t('KPI-successfully-Delete'), {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          autoHideDuration: 4000,
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar(
        error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.response?.data ||
        t('Errors.Server'), {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        autoHideDuration: 4000,
      });
      } finally {
        setOpenConfirmModal(false);
        setLoadingDelete(false);
      }
    }

    const createGrouper = (tipoEje) =>{
      switch (tipoEje) {
        case 'ESTADO': return 'estado'
        case 'CLIENTE': return 'clienteRazonSocial'
        case 'ACTIVOS': return 'tipoActivo'
        case 'CHOFERES': return 'chofer'
        case 'ESTADO_ESPECIFICO': 
        case 'INICIO': 
        case 'FINALIZACION': 
        case 'CREACION': return 'Tiempo'
        default: return tipoEje
      }
    }
    useEffect(() => {
      if (data.data && data.data.length > 0) {
        const {tipoEjeX, tipoEjeY} = data;

        const agrupador= createGrouper(data.tipoGrafico === "BARRA_VERTICAL"?tipoEjeY: tipoEjeX);
        const adder= (data.tipoGrafico === "BARRA_VERTICAL"?tipoEjeX: tipoEjeY) === "KILOMETROS"? "km": "cantidad";
        
        const week= lastSevenDaysOwnKPI(data.data, adder, agrupador);
        if (week?.length>0){
          setTF7D(week);
          copyTimeFrame[0].disabled=false
        }

        const month = lastMonthDataOwnKPI(data.data, adder, agrupador);
        if (month?.length>0){
          setTF1M(month);
          copyTimeFrame[1].disabled=false
        }
        
        const year = lastYearDataOwnKPI(data.data, adder, agrupador);
        if (year?.length>0){
          setTF1A(year);
          copyTimeFrame[2].disabled=false
        }

        const max = maxDataOwnKPI(data.data, adder, agrupador, firstSession);
        if (max?.length>0){
          setTFMax(max);
          copyTimeFrame[3].disabled=false
        }
        setSelecTimeFrame(copyTimeFrame[3])
        setDataToGraph(max);
        setTimeFrameState(copyTimeFrame);

      }
    }, [data]); 


    return( 
    <>
        <LiveGraphPreview
          id={data?.id}
          title={data?.titulo}
          chartType={data?.tipoGrafico}
          data={dataToGraph}
          mainDataKey="cantidad"
          dataGrouperKey="seccion"
          yAxisLabel={data?.nombreTipoEjeY}
          xAxisLabel={data.nombreTipoEjeX}
          showVerticalGrid={data?.lineaGuiaY}
          showHorizontalGrid={data?.lineaGuiaX}
          color={data?.color}
          selectedTimeFrame={selectedTimeFrame}
          timeFrameComponent={ 
            data.tipoData === 'CARGA' || data.tipoData === 'PEDIDO' || data.tipoData === 'VIAJE'? 
              <TimeFrame
                key={data?.id}
                timeFrame={timeFrameState}
                selectedTimeFrame={selectedTimeFrame}
                handleOnChangeTimeFrame={handleOnChangeTimeFrame}
                />
              : null
          }
          actions={{handleConfirmDelete, handleEdit}}
          legendContend={data?.nombreTipoData}
          atribute={data?.atributo}
        />
        <ModalConfirm
          open = {openConfirmModal}
          handleClose = {handleCloseModal}
          handleConfirm = {handleKPIdelete}
          msg = {t('KPI-Confirm-Delete')}
          title = {`${t('Delete-KPI')}: ${data.title}`}
          disabled = {loadingDelete}
        />
      </>
    )
  }

  export default KPIcreated;

